import { mapColor } from "shared/helpers/colorMapping"
import { rem } from 'shared/helpers/styleHelpers';

interface BGSpacingProps {
  bgColor?: string
  className?: string
  children: React.ReactNode
  noPadding?: boolean
  paddingTop?: number
  paddingBottom?: number
  isColumnCards?: boolean;
}

const Wrapper = ({ bgColor, className, children, noPadding, paddingTop, paddingBottom, isColumnCards }: BGSpacingProps) => {

  const wrapperPaddingTop = paddingTop ? rem(paddingTop) : rem(0);
  const wrapperPaddingBottom = paddingBottom ? rem(paddingBottom) : rem(0);

  const wrapperMargin = isColumnCards
    ? `${rem(90)} 0`
    : `${rem(0)} 0`

  return (
    <div className="component__margin"
      style={{
        margin: wrapperMargin,
      }}
    >
      {(bgColor || className) ?
        <div className={`${bgColor ? `${!noPadding && 'background--padding'} background--${mapColor(bgColor)}` : ''} ${className}`}
          style={{ paddingTop: wrapperPaddingTop, paddingBottom: wrapperPaddingBottom }}
        >
          {children}
        </div>
        : children
      }
    </div>
  )
}

export default Wrapper