import { Helmet } from 'react-helmet';
import { useStrapi } from "shared/context/strapi-graphql";
import { collectionQueryMap } from "shared/context/collectionQueryMap";
import StrapiDynamicZone from "components/Strapi/DynamicZone";
import StandardHero from "components/Hero";
import { Accordion } from "components/Accordion";
import { NewsCardCarousel } from "components/NewsCardCarousel/NewsCardCarousel";
import { ConnectWithExpert, H2TextIntroOneColumn, BrandBanner, IntroThreeColumn } from "components/CopyBlocks/Intro";
import ColumnCards from "components/ColumnCards";
import ImageBlockNew from "components/ImageBlocks";
import PhotoCollage from "components/Collages/PhotoCollage";
import { FilteredList } from "components/FilteredList/FilteredList";
import { ColorBar } from "components/ColorBar/ColorBar";
import { Iframe } from 'components/Iframe';
import './_index.scss';

const GenericTemplate = ({ slug }: { slug: string }): React.ReactElement | null => {
  const { pageData } = useStrapi({
    collections: collectionQueryMap(['Generic Pages']),
    slug: slug
  });

  const componentsData = pageData[0]?.components;
  const seoData = pageData[0]?.attributes.seo;
  if (!componentsData) return null

  return (
    <div>
      {seoData && <Helmet>
        <title>{seoData.meta_title || 'RVA Rising'}</title>
        <meta name="description" content={seoData.meta_description || 'RVA Rising'} />
      </Helmet>
      }
      <StrapiDynamicZone
        components={{
          StandardHero: StandardHero,
          Accordion: Accordion,
          NewsCardCarousel: NewsCardCarousel,
          ColumnCards: ColumnCards,
          ConnectWithExpert: ConnectWithExpert,
          ImageBlockNew: ImageBlockNew,
          H2TextIntroOneColumn: H2TextIntroOneColumn,
          PhotoCollage: PhotoCollage,
          FilteredList: FilteredList,
          BrandBanner: BrandBanner,
          ColorBar: ColorBar,
          IntroThreeColumn: IntroThreeColumn,
          Iframe: Iframe
        }}
        data={componentsData}
      />
    </div>
  )
}

export default GenericTemplate;