import React, { useEffect, useRef } from 'react';
import Wrapper from 'components/Strapi/Wrapper';

import './stylesheets/video-player.scss';

type VideoPlayerProps = {
  videoId: string;
  videoType: string;
  className?: string;
  children?: React.ReactNode;
};

export const VideoPlayer = ({ videoId, videoType, className, children }: VideoPlayerProps): React.ReactElement => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (iframeRef.current) {
      if (videoType === 'vimeo') {
        iframeRef.current.src = `https://player.vimeo.com/video/${videoId}?autoplay=1&muted=1&background=1`;
      } else if (videoType === 'youtube') {
        iframeRef.current.src = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&modestbranding=1&controls=0&rel=0`;
      }
    }
  }, [videoId, videoType]);

  return (
    <div className={`video-player ${className || ''}`} style={{ position: 'relative' }}>
      <iframe
        ref={iframeRef}
        width="100%"
        height="100%"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
      <div className="video-overlay-container">
        {children && children}
      </div>
    </div>
  );
};

export const VideoPlayerWithWrapper = (props: VideoPlayerProps) => {
  return (
    <Wrapper>
      <div className='container container--large'>
        <VideoPlayer {...props} />
      </div>
    </Wrapper>
  );
};
