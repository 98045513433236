import './stylesheets/header.scss';
import { useState, useRef, useEffect } from 'react';
import { Button } from 'components/Buttons';

const rvaHeaderLogo = 'https://rva-rising-strapi-bucket.s3.us-east-1.amazonaws.com/RVA_RISING_Logo_BLUE_b863c25d60.png'

export const Head = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && iconRef.current && !iconRef.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <div className='navbar'>
      <a href='/'>
        <img className='navbar__img' src={rvaHeaderLogo} alt='RVA Rising Logo' />
      </a>

      <div ref={iconRef} className='hamburger'>
        <Button
          customClassName='button-custom'
          icon={`${menuOpen ? 'xmark' : 'bars'}`}
          iconOnly={true}
          id='menu_btn'
          onClick={() => toggleMenu()}
        />
      </div>



      <div ref={menuRef} className={`navbar__options ${menuOpen ? 'navbar__options--open' : ''}`}>
        <a className='navbar__options__items' href='/ourwork'>Our Work</a>
        <a className='navbar__options__items' href='/whoweare'>Who We Are</a>
        <a className='navbar__options__items' href='/getinvolved'>Get Involved</a>
        <a className='navbar__options__items' href='/resources'>Resources</a>
      </div>
    </div>
  );
}

export const HeaderColorBar = () => {
  return (
    <div className='nav-color-bar'>
      <div className='nav-color-bar__yellow' />
      <div className='nav-color-bar__blue' />
    </div>
  )
}
