import React, { useEffect } from 'react';
import parse from 'html-react-parser';

import './stylesheets/iframe.scss';

interface IframeProps {
	desc: string;
}

export const Iframe = ({ desc }: IframeProps): React.ReactElement => {
	useEffect(() => {
		const scriptElement = document.createElement('script');
		scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
		scriptElement.async = true;
		document.body.appendChild(scriptElement);

		return () => {
			document.body.removeChild(scriptElement);
		};
	}, []);

	return <div>{parse(desc)}</div>;
};