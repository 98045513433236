import React from 'react';
import img1 from 'shared/assets/images/RVA_RISING_TRANS_Logo.png'

import './stylesheets/footer.scss';

export const Footer = (): React.ReactElement => {
  // Commented out for now will uncomment when client finishes Copy Right
  // const copyrightYear = new Date().getFullYear();

  return (
    <div className='footerWrapper'>
      <div className='footerWrapper__footer'>
        <div className='footerWrapper__footer__img-section'>
          <a data-testid='footer__link--img' href='/'>
            <img src={img1} alt='RVA Rising Logo' />
          </a>
        </div>
        <div className='footerWrapper__footer__links'>
          <div>
            <ul>
              <li><a data-testid='footer__link--our-work' href='/ourwork'>Our Work</a></li>
              <li><a data-testid='footer__link--who-we-are' href='/whoweare'>Who We Are</a></li>
            </ul>
          </div>
          <div>
            <ul>
              <li><a data-testid='footer__link--get-involved' href='/getinvolved'>Get Involved</a></li>
              <li><a data-testid='footer__link--know-the-facts' href='/resources'>Resources</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className='footerWrapper__paragraph-section'>
        <a
          href='https://www.google.com/maps/place/Community+Foundation/@37.5710973,-77.4741058,16z/data=!4m15!1m8!3m7!1s0x89b1140309e44d13:0x11348039f0cbb8d!2s3409+W+Moore+St,+Richmond,+VA+23230!3b1!8m2!3d37.5710973!4d-77.4741058!16s%2Fg%2F11c2b9_wt2!3m5!1s0x89b112e8d8f8e24d:0x8159b6ee4f2d6e61!8m2!3d37.570986!4d-77.4739297!16s%2Fg%2F1td1w9z8?entry=ttu&g_ep=EgoyMDI1MDEwMi4wIKXMDSoASAFQAw%3D%3D'
          data-testid='footer__link--address'
        >
          3409 W Moore St, Richmond, VA 23230
        </a>
        <p>RVA RISING Copyright 2025</p>
      </div>
    </div>
  )
};
